import React, {useEffect} from "react";
import Layout from "../_components/Layout/Layout";
import UserForm from "../_components/UserForm/UserForm";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import StyledLink from "../_components/StyledLink/StyledLink";
import {goto} from "../_utils/Misc";
import Qr from "../_utils/Qr";

const Registration = () => {
    useEffect(() => {
        if (!Qr.hasStoredParams()) {
            goto("/error");
        }
    }, []);

    return (
        <Layout title="Registration" hideSubNav={true}>
            <MainTitle className="user-form">Register to claim <br/> your Gift</MainTitle>

            <div className="form-subheader">
                <p>Sharing your preferences helps us deliver the best experience to you and be sure you use a valid
                    email to
                    receive your gift!</p>
            </div>

            <UserForm showEmail={true} btnText="CLAIM MY GIFT"/>

            {/* <hr /> */}

            {/* <PageSummary>
                <small className="text-muted">
                    Sign up to our newsletter to hear about Taste of London and similar events, products and services.
                    Taste Festivals Limited and International Management Group (UK) Limited are the controllers of your
                    data. Click Unsubscribe in any email to withdraw your consent or change your preferences as
                    described in our <a href="/privacy/" style={{ "textDecoration": "underline" }} target="_blank">Privacy
                    Policy</a>
                </small>
            </PageSummary> */}

            {/* <hr /> */}

            {/* <StyledLink to="/login">Already registered? Click here</StyledLink> */}
        </Layout>
    );
};

export default Registration;
